<template>
  <div class="p-grid p-jc-center">
    <div class="p-col-8">
      <Fieldset legend="ข้อเสนอแนะ/ข้อมูลที่อยากได้จากระบบ">
        <div style="margin-bottom: 5px">
          <div class="p-d-flex p-jc-between">
            <Button
              label="ย้อนกลับ"
              icon="pi pi-arrow-left"
              class="p-button-sm p-button-warning"
              @click="() => $router.back(-1)"
            />
          </div>
        </div>

        <DataTable
          :value="suggests"
          ref="dt"
          dataKey="id"
          :paginator="true"
          :rows="20"
          v-model:filters="dataFilters"
          paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
          :rowsPerPageOptions="[20, 50, 100]"
          currentPageReportTemplate="แสดง {first} ถึง {last} จากข้อมูล {totalRecords}"
          class="p-datatable-gridlines p-datatable-striped p-datatable-responsive-demo"
        >
          <!-- ื่อ , นามสกุล , User_name, ระดับ User -->
          <Column
            field="no"
            header="ลำดับที่"
            :sortable="true"
            style="width: 8%; white-space: nowrap; text-align: center"
          ></Column>
          <Column
            field="createDtm"
            header="วันที่ข้อเสนอแนะ"
            :sortable="true"
          ></Column>
          <Column
            field="areaName"
            header="พื้นที่/ทีมวิจัย"
            :sortable="true"
          ></Column>
          <Column
            field="partnerName"
            header="ภาคีเครือข่ายร่วมพัฒนาผู้สูงอายุ"
            :sortable="true"
          ></Column>
          <Column
            field="suggestion"
            header="ข้อเสนอแนะ"
            :sortable="true"
          ></Column>
        </DataTable>
      </Fieldset>
    </div>
  </div>
</template>

<script>
export default {
  layout: 'Public',
  data() {
    return {
      suggest: {}
    }
  },
  computed: {
    suggests() {
      const { areas, partners } = this.$store.state.constance
      return this.$store.state.suggest.suggests.map((sug, index) => {
        const { areaCode, partnerCode } = sug
        return {
          ...sug,
          no: index + 1,
          areaName: areas[areaCode],
          partnerName: partners[partnerCode]
        }
      })
    }
  },
  async created() {
    await this.$store.dispatch('suggest/fetchSuggests', {})
  },
  mounted() {},
  methods: {}
}
</script>

<style>
</style>